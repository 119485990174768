import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { YoungService } from 'src/app/service/young.service';
import { Young } from 'src/app/model/young.model';
import { DatePipe } from '@angular/common';
import { EditModalComponent } from 'src/app/utils/component/edit-modal/edit-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'plm-young-panel',
  templateUrl: './young-panel.component.html',
  styleUrls: ['./young-panel.component.css']
})
export class YoungPanelComponent implements OnInit {

  youngInformationActive: boolean;
  youngAwarenessActive: boolean;
  youngAccompanyingActive: boolean;
  currentYoung: Young;

  constructor(private route: ActivatedRoute, private youngservice: YoungService, private modalService:NgbModal) {
    this.youngInformationActive = true;
    this.youngAwarenessActive = false;
    this.youngAccompanyingActive = false;
  }

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    if (id) {
      this.youngservice.getYoungById(id).subscribe(young => {
        this.currentYoung = young;
      });
    } else {
      this.currentYoung = new Young();
    }
  }


  setYoungInformationActive() {
    this.youngInformationActive = true;
    this.youngAwarenessActive = false;
    this.youngAccompanyingActive = false;
  }

  setYoungAwarenessActive() {
    this.youngAwarenessActive = true;
    this.youngInformationActive = false;
    this.youngAccompanyingActive = false;
  }


  setYoungAccompanyingActive() {
    this.youngAwarenessActive = false;
    this.youngInformationActive = false;
    this.youngAccompanyingActive = true;
  }

  saveYoung(young: Young) {
    console.log('saveYoung' + young);
    this.currentYoung = young;
  }

  editWarningModal(editWarningTemplate: EditModalComponent) {
    let warning = {
     "value": this.currentYoung.warning
   }
   editWarningTemplate.setValue(warning);
   this.modalService.open(editWarningTemplate.templateModal).result
     .then(
       (result) => this.currentYoung.warning = result.value,
       (dismiss) => console.log(dismiss)
     );
 }
}
