import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent implements OnInit {


  @Input()
  title: string;

  @Input()
  label: string;

  @Input()
  required: boolean;

  originalData:any;


  @ViewChild("templateModal")
  templateModal: TemplateRef<any>;


  valueForm: FormGroup;


  ngOnInit(): void {
   
    let valueValidators:ValidatorFn[] = [];
    if(this.required == undefined || this.required) {
      valueValidators.push(Validators.required);
    }
    
    this.valueForm = new FormGroup({
      id: new FormControl('', Validators.compose([])),
      value: new FormControl('',  valueValidators)
    });

  }

  setValue(data:any){
    this.originalData = data;
    this.valueForm.patchValue(data);
  }
  
  save(modal: NgbModalRef) {
    if (this.valueForm.valid) {
     
      modal.close( Object.assign(this.originalData, this.valueForm.value));
      //this.onSave.emit(this.valueForm.value);
    }
  }

  cancel(modal: NgbModalRef){
    modal.dismiss();
  }

  isControlInvalid(formControl: AbstractControl) {
    return (formControl.dirty || formControl.touched)
      && formControl.invalid
  }

}
