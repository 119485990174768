import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DropDownService } from 'src/app/service/drop-down.service';
import { EditModalComponent } from 'src/app/utils/component/edit-modal/edit-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'plm-dropdown-parameter',
  templateUrl: './dropdown-parameter.component.html',
  styleUrls: ['./dropdown-parameter.component.css']
})
export class DropdownParameterComponent implements OnInit {

  public dropdownValues: any;

  public newValues = {};

  @ViewChild("editDropDownTemplate")
  editDropDownTemplate: TemplateRef<any>;

  constructor(private dropDownService: DropDownService, private modalService:NgbModal) { }

  ngOnInit() {
    this.reloadDropDownValue();
  }

  reloadDropDownValue() {
    this.dropDownService.getDropDownValues().subscribe(dropDownValues => this.dropdownValues = dropDownValues);
  }

  addValue(key: string, values: any[]) {
    const newValue = this.newValues[key];
    if (newValue) {
      console.log(values);
      const maxOrder = values.reduce((value1, value2) => ({ 'order': Math.max(value1.order, value2.order) }), { 'order': 0 });
      console.log(maxOrder);
      const value = this.newValues[key];
      this.dropDownService.addDropdownValue(key, value, maxOrder.order + 1)
        .subscribe((dropdownValue) => { values.push(dropdownValue); this.newValues[key] = undefined; });
    }
  }

  deleteValue(id: number) {
    this.dropDownService.deleteValue(id).subscribe(res => this.reloadDropDownValue());
  }

  editValue(dropDownValue:any) {
    let modal: EditModalComponent = this.editDropDownTemplate as unknown as EditModalComponent;
    let values = {
      "id": dropDownValue.id,
      "value": dropDownValue.value,
      "order" : dropDownValue.order
    }
    modal.setValue(values);
    this.modalService.open(modal.templateModal).result
      .then(
        (result) => this.saveDropDown(result),
        (dismiss) => console.log(dismiss)
      );
  }

  saveDropDown(dropDownValue: any): any {
    this.dropDownService.saveDropDownValue(dropDownValue).subscribe(()=>this.reloadDropDownValue())
  }
}
